import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout/Layout";
import ErrorMessage from "../components/form/ErrorMessage";
import checkProductsAvailability from "../utils/product";
import BreadCrumbs from "../components/common/breadcrumbs/BreadCrumbs";
import ProductImages from "../components/products/ProductImages";
import ProductInfo from "../components/products/ProductInfo";
import useGetProductDetails from "../hooks/products/useGetProductDetails";
import Price from "../components/products/Price";
import OptionForm from "../components/products/OptionForm";
import RealtedProducts from "../sections/product/RelatedProducts";
import VideoSection from "../sections/product/VideoSection";
import DescriptionSection from "../sections/product/DescriptionSection";
import ReviewSection from "../sections/product/ReviewSection";
import useGetProductOptions from "../hooks/products/useGetProductOptions";
import { isUserLoggedIn } from "../utils/auth";

const ProductDetails = ({
  location,
  data: {
    allBigCommerceProducts: {
      nodes: [{ name, bigcommerce_id, sku }],
    },
    bigCommerceBrands,
  },
}) => {
  const user = isUserLoggedIn();
  const { productDetails } = useGetProductDetails(bigcommerce_id);
  const { product_variants } = useGetProductOptions(bigcommerce_id, user);

  const {
    price,
    retail_price,
    sale_price,
    images,
    custom_fields,
    upc,
    order_quantity_minimum,
    order_quantity_maximum,
    weight,
    condition,
    availability,
    description,
    warranty,
    reviews_count,
    reviews_rating_sum,
    videos,
    reviews,
    related_products,
    breadcrumb_url,
    // fixed_cost_shipping_price,
    // inventory_is_in_stcok,
  } = productDetails;

  return (
    <Layout>
      <div className="product-detail-wrap">
        <div className="page-heading-section text-center page-heading-section-small">
          <BreadCrumbs location={location} url={breadcrumb_url} />
        </div>
        <section className="product-view-section">
          <div className="container">
            <div className="bc-product-single">
              <div className="row flex flex-wrap vertical-top">
                <div className="col product-image-section">
                  <section className="bc-product-single__top">
                    <ProductImages images={images} />
                  </section>
                </div>
                <div className="col product-detail-section">
                  <div className="bc-product-single__meta">
                    <h1 className="bc-product__title">{name}</h1>

                    <h2 className="product-brand-name">
                      {bigCommerceBrands && bigCommerceBrands.name}
                    </h2>
                    <ReviewSection
                      reviewsCount={reviews_count}
                      reviews_rating_sum={reviews_rating_sum}
                    />
                    <Price
                      sale_price={sale_price}
                      price={price}
                      retail_price={retail_price}
                    />
                    <ProductInfo
                      sku={sku}
                      upc={upc}
                      availability={availability}
                      order_quantity_minimum={order_quantity_minimum}
                      order_quantity_maximum={order_quantity_maximum}
                      weight={weight}
                      condition={condition}
                    />
                    <OptionForm
                      min_purchase_qty={order_quantity_minimum}
                      max_purchase_qty={order_quantity_maximum}
                      data={product_variants}
                      bigcommerce_id={bigcommerce_id}
                    />
                    {checkProductsAvailability(availability) && (
                      <ErrorMessage>{`Product is currently ${availability}`}</ErrorMessage>
                    )}
                  </div>
                </div>
              </div>

              <DescriptionSection
                description={description}
                warranty={warranty}
                reviews={reviews}
                customFields={custom_fields}
              />
              <VideoSection videos={videos} />
              <RealtedProducts relatedProducts={related_products} />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const Head = ({ pageContext }) => {
  const { productDetails } = useGetProductDetails(pageContext?.bigcommerceId);
  const pageTitle = pageContext?.pageTitle
    ? pageContext?.pageTitle
    : `${pageContext?.name} | Midwest Distribution`;

  const metaDescription = pageContext.metaDescription
    ? pageContext.metaDescription
    : String(productDetails?.description).substring(0, 160);
  return (
    <>
      <title>{pageTitle}</title>
      <meta name="description" content={metaDescription} />
    </>
  );
};

export default ProductDetails;

export const query = graphql`
  query ($productId: String!, $brandId: Int) {
    allBigCommerceProducts(filter: { id: { eq: $productId } }) {
      nodes {
        bigcommerce_id
        name
        sku
      }
    }
    bigCommerceBrands(bigcommerce_id: { eq: $brandId }) {
      name
    }
  }
`;
